
import { useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { FormulariPrincipal } from "../Component/Evaluacion/FormulariPrincipal";
import { MenuPrincipal } from "../Component/MenuPrincipal/MenuPrincipal";
import { MenuPrincipalADMIN } from "../Component/MenuPrincipal/MenuPrincipalADMIN";
import { Resultats } from "../Component/MenuPrincipal/Resultats/Resultats";
import { ModificarScreen } from "../Component/register/ModificarScreen";

import { FooterPublic } from "../Component/ui/FooterPublic";

import { HeaderPrivat } from "../Component/ui/HeaderPrivat";

import FormularioAdmin from "../Component/ModificarFormulario/FormularioAdmin";


export const DashboardRoutes = () => {
    
    const { auth } = useSelector(state => state);

    return (
        <>
        <HeaderPrivat />
        <div className="container p-8" id="cos">

        
            {auth.admin ==0 ? 
            <Routes>
                <Route path="/" element={<MenuPrincipal />} />
                <Route path="/menu" element={<MenuPrincipal />} />
                <Route path="/modificar" element={<ModificarScreen />} />
                <Route path="/evaluacion" element={<FormulariPrincipal />} /> 
                <Route path="/resultats" element={<Resultats />} /> 
            </Routes>
            
            :
            <Routes>
                <Route path="/" element={<MenuPrincipalADMIN />} />
                <Route path="/menu" element={<MenuPrincipalADMIN />} />
                <Route path="/evaluacion" element={<FormularioAdmin />} /> 
                <Route path="/modificar" element={<ModificarScreen />} />
                <Route path="/resultats" element={<Resultats />} /> 
            </Routes>
            
            }
        
           
           
        </div>

        <FooterPublic  />
        </>
            
        
    )
}

