import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { infoTextCategoria, updateTextosModificats } from '../../actions/configFormularioAction';
import { useTranslation } from "react-i18next";
import { Pregunta } from '../../helpers/Pregunta';
import { uiActualizarTextosStateAction } from '../../actions/ui';

export const ComponenteFormularioTexto = ({ catIndex }) => {  // Recibe `catIndex` como prop
  const dispatch = useDispatch();
  const [t] = useTranslation("generic");
  const { auth, configFormulario, ui } = useSelector((state) => state);
  const { infoFormularioTextCategoria } = configFormulario;

  const [editableQuestions, setEditableQuestions] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga inicial

  // Lógica para cargar datos al montar el componente
  useEffect(() => {
    const keyFilter = ui.textosState === 2 ? 'Prog' : 'Preg'; // Filtra entre "Prog" y "Preg"

    // Accede de manera dinámica a la categoría que se pasa como prop (catIndex)
    const categoryKey = `Categoria${catIndex}`;
    const categoryData = infoFormularioTextCategoria[categoryKey] || {};

    // Filtrar y mapear las preguntas
    const initialQuestions = Object.keys(categoryData)
      .filter((key) => key.includes(keyFilter)) // Filtra por tipo de pregunta
      .map((key) => ({
        id: key,
        type: 'text',
        value: categoryData[key] || '',
      }));

    // Obtener desplegables específicos relacionados con las preguntas
    const desplegables = ui.textosState === 1 ? Object.keys(categoryData)
      .filter((key) => key.includes('Desplegable')) // Filtra las claves que incluyen "Desplegable"
      .map((key) => {
        const desplegableNumber = key.match(/Desplegable(\d+)/)?.[1]; // Extrae el número después de "Desplegable"

    // Encontramos la pregunta relacionada con ese número
    const relatedQuestion = initialQuestions.find((question) => {
      // Extraemos el número de la pregunta (por ejemplo "1" de "Cat4_Preg1")
      const questionNumber = question.id.match(/Cat\d+_Preg(\d+)/)?.[1]; // Extrae el número después de "Preg"
      return questionNumber === desplegableNumber; // Comparamos el número de la pregunta con el número del desplegable
    });// Relacionado con una pregunta específica
        return {
          id: key,
          type: 'dropdown', // Indica que es un desplegable
          value: categoryData[key] || '',
          relatedQuestion: relatedQuestion?.id || null, // Vincula si tiene relación
        };
      }):[];

    // Separar los desplegables relacionados y los de tipo `X`

    const relatedDesplegables = desplegables
      .filter((d) => d.relatedQuestion !== null)
      .reverse(); // Invertir el orden de los desplegables relacionados

    const finalDesplegables = desplegables
      .filter((d) => d.id.includes('DesplegableX'))
      .reverse(); // Invertir el orden de los desplegables de tipo `X`

    // Insertar los desplegables relacionados entre las preguntas
    const questionsWithDesplegables = initialQuestions.reduce((acc, question) => {
      acc.push(question); // Añadir la pregunta actual
      // Añadir los desplegables relacionados con esta pregunta
      relatedDesplegables
        .filter((d) => d.relatedQuestion === question.id)
        .forEach((d) => acc.push(d));
      return acc;
    }, []);

    // Agregar los desplegables de tipo `X` al final
    const finalList = [...questionsWithDesplegables, ...finalDesplegables];

    // Agregar el título al principio si corresponde
    const title = ui.textosState === 1 ? categoryData[`Cat${catIndex}_Titol`] || '' : ''; // Título específico según estado
    const questionsWithTitle = title ? [{ id: `Cat${catIndex}_Titol`, type: 'title', value: title }, ...finalList] : finalList;

    // Actualizar estado
    setEditableQuestions(questionsWithTitle);
    setLoading(false); // Datos cargados, desactiva la carga

}, [infoFormularioTextCategoria, dispatch, auth.tokenJwt, ui.textosState, catIndex]);


  // Manejar cambios en las preguntas editables
  const handleQuestionChange = (e, id) => {
    const updatedQuestions = editableQuestions.map((question) =>
      question.id === id ? { ...question, value: e.target.value } : question
    );
    setEditableQuestions(updatedQuestions);
  };

  // Enviar datos modificados
  const handleModificarTextos = () => {
    const dataToSend = {};
    const categoryKey = `Categoria${catIndex}`;
    editableQuestions.forEach((question) => {
      
      if (infoFormularioTextCategoria[categoryKey][question.id] !== question.value) {
        dataToSend[question.id] = question.value;
      }
    });

    if (Object.keys(dataToSend).length > 0) {
      console.log("Datos enviados:", dataToSend);
      dispatch(uiActualizarTextosStateAction(2));
      dispatch(updateTextosModificats(auth.tokenJwt, dataToSend, categoryKey));
    } else {
      console.log("No se hicieron cambios.");
    }
  };

  // Renderizado condicional mientras carga
  if (loading) {
    return <div>Loading...</div>; // Muestra un spinner o mensaje de carga
  }

  
  return (
    <div className="ac-panel">
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {editableQuestions.map((question, index) => (

          <Pregunta
          key={`${question.id}_${index}`}
            index={index} 
            state={ui.textosState}
            label={question.value}
            name={question.id}
            value={question.value}
            type={question.type}
            isAdmin={true}
            previousQuestion={editableQuestions[index - 1] || null} 
            onQuestionTextChange={(e) => handleQuestionChange(e, question.id)}
          />

        ))}
        <div>
          <button onClick={handleModificarTextos} id={`cat${catIndex}`} className="btn-turquesa">
            Guardar Nuevos Textos
          </button>
        </div>
      </div>
    </div>
  );
};
