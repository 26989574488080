import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConstantsModificats, updateTextosModificats } from "../../actions/configFormularioAction";
import { useTranslation } from "react-i18next";

export const ComponenteFormularioConstantes = ({ catIndex }) => {
  const dispatch = useDispatch();
  const [t] = useTranslation("generic");
  const { auth, configFormulario } = useSelector((state) => state);
  const { infoFormularioTextCategoria, infoFormularioConstantesCategoria } = configFormulario;

  const [editableQuestions, setEditableQuestions] = useState([]);

  useEffect(() => {
    // Verificación para evitar errores si los datos aún no han cargado
    if (!infoFormularioTextCategoria || !infoFormularioConstantesCategoria) return;

    const categoryKey = `Categoria${catIndex}`;
    const categoryTextData = infoFormularioTextCategoria[categoryKey] || {};
    const categoryConstantesActual = infoFormularioConstantesCategoria.actualVersion?.[categoryKey] || {};
    const categoryConstantesNueva = infoFormularioConstantesCategoria.nuevaVersion?.[categoryKey] || {};

    // Filtrar solo preguntas que contienen "Prog"
    const formattedQuestions = Object.keys(categoryTextData)
      .filter((key) => key.includes("Preg")) // Filtrar claves que contengan "Prog"
      .map((key) => {
        const match = key.match(/Preg(\d+)/); // Extraer el número después de "Prog"
        const questionNumber = match ? match[1] : "X"; // Si no hay número, asigna "X"
        const constantKey = `${catIndex}.${questionNumber}`; // Formato correcto "1.1", "1.2", etc.

        const actualValue = categoryConstantesActual[constantKey];
        const newValue = categoryConstantesNueva[constantKey] || "";

        // Si no existe la constante, mostrar "No existe constante para esta pregunta" en ambos lugares
        const displayValue = actualValue !== undefined ? newValue : "No existe constante para esta pregunta";

        return {
          id: key,
          text: categoryTextData[key] || "Pregunta sin texto",
          actualValue: actualValue !== undefined ? actualValue : "-",
          newValue: displayValue,
          editable: actualValue !== undefined, // Si no hay constante, no es editable
        };
      });

    setEditableQuestions(formattedQuestions);
  }, [infoFormularioTextCategoria, infoFormularioConstantesCategoria, catIndex]);

  const handleQuestionChange = (e, id) => {
    const updatedQuestions = editableQuestions.map((question) =>
      question.id === id ? { ...question, newValue: e.target.value } : question
    );
    setEditableQuestions(updatedQuestions);
  };

  const handleModificarTextos = () => {
    const dataToSend = {};
    
    editableQuestions.forEach((question) => {
      // Transformar question.id de 'Cat9_Preg2' a '9.2'
      const formattedKey = question.id.replace(/^Cat(\d+)_Preg(\d+)$/, '$1.$2');
      
      const currentCategory = infoFormularioConstantesCategoria.nuevaVersion?.[`Categoria${catIndex}`];
        
      // Comparar si el valor ha cambiado
      if (question.editable && question.newValue !== currentCategory?.[formattedKey]) {
        dataToSend[formattedKey] = question.newValue;
      }
    });
  
    if (Object.keys(dataToSend).length > 0) {
      console.log("Datos enviados:", dataToSend);
       dispatch(updateConstantsModificats(auth.tokenJwt, dataToSend, `Categoria${catIndex}`,infoFormularioConstantesCategoria.nuevaVersion.version));
    } else {
      console.log("No se hicieron cambios.");
    }
  };
  
  

  return (
    <div className="ac-panel">
      <div className="ac-text !p-9 !pt-3 !text-[15px] !font-normal !leading-7 !text-dark-light">
        {editableQuestions.map((question, index) => (
          <div key={index} className="mb-4">
            {/* Pregunta + Valor actual (sin edición) */}
            <p className="font-medium">
              {question.text}: <span className="text-gray-500">{question.actualValue}</span>
            </p>

            {/* Input editable debajo (deshabilitado si no existe la constante) */}
            <input
              type="text"
              className="w-full p-2 border rounded"
              value={question.newValue}
              onChange={(e) => handleQuestionChange(e, question.id)}
              disabled={!question.editable} // Si no existe constante, deshabilita el input
            />
          </div>
        ))}
        <div>
          <button onClick={handleModificarTextos} id={`cat${catIndex}`} className="btn-turquesa">
            Guardar Nuevos Valores
          </button>
        </div>
      </div>
    </div>
  );
};
