import { useState } from "react";
import { FormularioTextosAdmin } from "./FormularioTextosAdmin";
import { FormularioConstantesAdmin } from "./FormularioConstantesAdmin";

export default function FormularioAdmin() {
  const [componenteActual, setComponenteActual] = useState("constantes");

  return (
    <div className="p-4">
      <h1
        className={`cursor-pointer text-blue-600 underline ${
          componenteActual === "constantes" ? "font-bold" : ""
        }`}
        onClick={() => setComponenteActual("constantes")}
      >
        Formulario Constantes
      </h1>

      <h1
        className={`cursor-pointer text-green-600 underline ${
          componenteActual === "categorias" ? "font-bold" : ""
        }`}
        onClick={() => setComponenteActual("categorias")}
      >
        Formulario de Textos
      </h1>

      <div className="mt-4">
        {componenteActual === "constantes" ? (
          <FormularioConstantesAdmin />
        ) : (
          <FormularioTextosAdmin />
        )}
      </div>
    </div>
  );
}
