import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {  infoCategoria, infoTextCategoria, recuperarConstantesIndicadoresFormulario } from "../../actions/configFormularioAction";
import { uiFinalitzarFormularioAction, esborrarErrorAction, uiActualizarTextosStateAction } from "../../actions/ui";
import { ModificarScreen } from "../register/ModificarScreen";
import { ComponenteFormularioTexto } from "./ComponenteFormularioTexto";

export const FormularioTextosAdmin = () => {
  const [t, i18n] = useTranslation("generic");
  const dispatch = useDispatch();
  const [cat, setCat] = useState("1");
  const navegacio = useNavigate();
  const [primerCop, setPrimerCop] = useState(true);
  const { auth, configFormulario, ui, configUsuari } = useSelector(state => state);
  const { infoFormulario, infoFormularioTextCategoria } = configFormulario;

  const handleObrirCat = (e, cerrar,id) => {
    e.preventDefault();
    if (id == cat && cerrar) {
        setCat(null);
        dispatch(uiActualizarTextosStateAction(1));

    } else {
      if(cerrar){
        dispatch(uiActualizarTextosStateAction(1));
      }
      setCat(id);
      
    }
    dispatch(esborrarErrorAction());
  };

  useEffect(() => {
    if (primerCop) {
      if (infoFormulario != null) {
        dispatch(infoTextCategoria(auth.tokenJwt));
        dispatch(uiActualizarTextosStateAction(1));
      }
      if (infoFormularioTextCategoria != null) {
        setCat(infoFormularioTextCategoria.CatActual);
        dispatch(infoTextCategoria(auth.tokenJwt));
        setPrimerCop(false);
        dispatch(uiActualizarTextosStateAction(1));
      }
    }
  }, [configFormulario, cat]);

  const [disabled, setDisabled] = useState({
    cat1: false,
    cat2: false,
    cat3: false,
    cat4: false,
    cat5: false,
    cat6: false,
    cat7: false,
    cat8: false,
    cat9: false,
    cat10: false
  });

  useEffect(() => {
    if (!ui.formulari) {
      navegacio("/");
    }
    if (ui.msgError !== null) {
      setTimeout(() => dispatch(esborrarErrorAction()), 10000);
    }
  }, [ui]);

  return (
    <>
      
      <h2 className="font-semibold mt-4 mb-4 text-4xl leading-snug lg:text-5xl lg:leading-[62px]">Formulario de Textos</h2>
      {infoFormularioTextCategoria != null ?
        <div className="accordion-container faq space-y-4">
          <div className="ac mt-0 bg-white rounded-lg border-none">
            <h2 className="ac-header">
              {/* Renderizamos las categorías dinámicamente */}
              {Array.from({ length: 9 }, (_, i) => i + 1).map((catIndex) => (
                <div key={catIndex}>
                <div key={catIndex} className="flex">
                  {/* Botón para abrir General */}
                  <button
                    onClick={(e) => handleObrirCat(e, true,catIndex.toString())}
                    className="ac-trigger !px-8 !py-[18px] relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                    disabled={disabled[`cat${catIndex}`]}
                  >
                    <span className="text-medium text-dark-semilight uppercase">
                      {/* Título dinámico usando infoFormularioCategoria y el índice */}
                      {infoFormularioTextCategoria ? infoFormularioTextCategoria?.[`Categoria${catIndex}`]?.[`Cat${catIndex}_Titol`] : null}
                    </span>

                  </button>
                  {/* Div con tres botones i arrow */}
                  <div className="flex gap-4 " >
                  <div className="flex gap-4 mt-4">
                      <button
                        onClick={(e) => {
                          dispatch(uiActualizarTextosStateAction(1));
                          handleObrirCat(e, false,catIndex.toString());
                          
                        }}
                        className="bg-blue-500 text-red p-2 rounded-md"
                      >
                        Texto
                      </button>
                      <button
                        onClick={(e) => {
                          dispatch(uiActualizarTextosStateAction(2));
                          handleObrirCat(e, false,catIndex.toString());
                        }}
                        className="bg-green-500 text-red p-2 rounded-md"
                      >
                        Programas
                      </button>
                      <button
                        onClick={() => dispatch(uiActualizarTextosStateAction(3))}
                        className="bg-red-500 text-red p-2 rounded-md"
                      >
                        PDF
                      </button>
                    </div>
                  <div className=" flex gap-4 mt-8  faq-arrow">
                    
                    {cat !== catIndex.toString() ?
                      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#e20078" />
                      </svg>
                      :
                      <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.0466659 0.744778L3.86978 5.53751C3.93301 5.61663 4.06672 5.61663 4.12995 5.53751L7.95348 0.744445C7.99899 0.68712 8.01247 0.601674 7.98793 0.527294C7.98285 0.511735 7.97719 0.499838 7.97245 0.490935C7.94325 0.436855 7.88717 0.403076 7.8256 0.403076H0.174128C0.112893 0.403076 0.0564835 0.436772 0.02753 0.490935C0.0226212 0.500004 0.0169636 0.511985 0.0122212 0.526378C-0.0124892 0.601674 0.000906017 0.687287 0.0466659 0.744778Z" fill="#3EC37D"></path>
                      </svg>
                    }
                  </div>
                  </div>



                  {/* Componente FormularioCat dinámico */}
                  
                </div>
                {cat == catIndex  ? <ComponenteFormularioTexto key={`form-category-${catIndex}`}  catIndex={catIndex} /> : null}
                </div>
              ))}
            </h2>
          </div>
        </div>
        : null}
    </>
  );
};
